<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="1" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.payslip_explanation") }}
        </v-card-title>
        <v-card-text class="pa-5" style="display: flex; justify-content: center; align-items: center">
          <img src="@/assets/payslip_desc.png" alt="Payslip Explanation" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "PayslipExplanation"
  };
</script>

<style scoped></style>
